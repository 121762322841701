import { Modal, Form, Button, InputGroup, Row, Col } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { useBackendUrl, useEffectOnce } from "../utils/hooks";
import NumberCustomInput from "./utils/NumberCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import {
  consumptionTypes,
  customerFullName,
  // vendorFullName,
  qtyFormat,
  tonsToPcs,
  Units,
  unitsResolver,
} from "../utils/helpers";
import { appSettings } from "../config";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import ItemSizeDialog from "./ItemSizeDialog";
import { useEffect } from "react";
import currency from "currency.js";
import { Popover } from "react-tiny-popover";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import eventBus from "../utils/EventBus";
import { useMemo } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import { lowerCase, isEmpty } from "lodash";
import ConvertQuantity from "./utils/ConvertQuantity";
import ConfirmDialog from "./ConfirmDialogue";
import VendorSelector from "./Vendor/VendorSelector";
// import VendorSearchableSelect from "./utils/VendorSearchableSelect";

export default function CreateBatchConsumptionModal({
  showCreateBatchConsumptionModal = true,
  selectedBatchItem,
  setShowCreateBatchConsumptionModal,
  refetch,
}) {
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const backendUrl = useBackendUrl();

  const initialValues = {
    Bar_Code: selectedBatchItem.Bar_Code,
    amount: 0,
    type: "",
    title: "",
    quantity: 1,
    salesDate: moment(),
    subTotal: 0,
    remark: "",
  };
  const schema = yup.object().shape({
    amount: yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: "Post Consumption",
          description: "Are you sure, you want to post",
        })
      ) {
        outsourceMutation.mutate({
          ...values,
        });
      }
    },
  });

  const outsource = async (payload) => {
    let response = await fetch(`${backendUrl}/api/consumption/add`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const outsourceMutation = useMutation((payload) => outsource(payload), {
    onSuccess: ({ message, data }) => {
      if (refetch) refetch();
      toast.success(message);
      setShowCreateBatchConsumptionModal(false);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  useEffect(() => {
    formik.setFieldValue(
      "subTotal",
      currency(formik.values.amount).multiply(formik.values.quantity).value
    );
  }, [formik.values.amount, formik.values.quantity]);

  return (
    <Modal
      show={showCreateBatchConsumptionModal}
      onHide={() => {
        setShowCreateBatchConsumptionModal(false);
      }}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">
            Add Consumption - {selectedBatchItem?.ItemName}/
            {selectedBatchItem?.Bar_Code}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>

            <Form.Control
              as={"textarea"}
              name="title"
              placeholder="Enter Title"
              value={formik.values.title}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <Select
              options={consumptionTypes}
              value={consumptionTypes.find(
                (el) => el.value === formik.values.type
              )}
              onChange={(selected) =>
                formik.setFieldValue("type", selected.value)
              }
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Quantity</Form.Label>

            <NumberCustomInput
              name="quantity"
              value={formik.values.quantity}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.quantity && !!formik.errors.quantity}
            />
            {formik.touched.quantity && !!formik.errors.quantity ? (
              <span className="custom-invalid-feedback">
                {formik.errors.quantity}
              </span>
            ) : null}
          </Form.Group>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Amount</Form.Label>
                <CurrencyCustomInput
                  name="amount"
                  value={formik.values.amount}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.amount && !!formik.errors.amount}
                />
                {formik.touched.amount && !!formik.errors.amount ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.amount}
                  </span>
                ) : null}
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>SubTotal</Form.Label>
                <CurrencyCustomInput
                  name="subTotal"
                  value={formik.values.subTotal}
                  onValueChange={(value, name) => {}}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label>Remark</Form.Label>
            <Form.Control
              as={"textarea"}
              name="remark"
              placeholder="Enter Remark"
              value={formik.values.remark}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Date</Form.Label>
            <Datetime
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              dateFormat="MMM DD, YYYY"
              name="salesDate"
              inputProps={{
                className: `date-input form-control ${
                  formik.touched.salesDate && !!formik.errors.salesDate
                    ? "is-invalid"
                    : ""
                }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              value={formik.values.salesDate}
              onChange={(date) => {
                formik.setFieldValue("salesDate", date, true);
              }}
              onBlur={() => formik.setFieldTouched("salesDate", true)}
            />
          </Form.Group>

          <Button
            disabled={outsourceMutation.isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {outsourceMutation.isLoading ? "Please wait…" : "Post"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
