import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { useIsAdmin } from "../../utils/hooks";

export function Nav(props) {
  const isAdmin = useIsAdmin();
  const items = useMemo(
    () => [
      {
        name: "Quotation",
        to: "quotation",
      },
      {
        name: "Proforma",
        to: "proforma",
      },
      {
        name: "Invoice List",
        to: "invoice-list",
      },
      {
        name: "Customer List",
        to: "customer-list",
      },
      ...(isAdmin
        ? [
            {
              name: "Customer Type",
              to: "customer-type",
            },
          ]
        : []),

      {
        name: "Inventory",
        to: "inventory",
      },
      {
        name: "Manage Transaction",
        to: "manage-transaction",
      },
      {
        name: "Enquires List",
        to: "enquire-list",
      },
      {
        name: "Due Invoices",
        to: "due-invoices",
      },
    ],
    [isAdmin]
  );

  return (
    <div className="dashboard-tabs innerpage-tabs px-4">
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
