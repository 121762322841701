import { Form, InputGroup, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";
import { useState } from "react";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Select from "react-select";

import { fetchActionsUtil } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import { didYouKnowOptions } from "../../utils/helpers";
import { initialValues, schema } from "./types";
import "../../assets/scss/new-entity-modal.scss";
import ModalLoader from "../utils/ModalLoader";
import CitySelector from "../CitySelector";

export const RegisterCustomer = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const { customerBackendUrl: backendUrl } = useAuth();

  const createCustomerMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/customers/create`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message, customer }) => {
        toast.success(message);
        if (props.createdCustomer) props.createdCustomer(customer);
        props?.onHide();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      const { DOB, DateLog } = values;
      if (typeof DOB === "string") {
        formik.setFieldError("DOB", "Invalid date");
      }
      if (typeof DateLog === "string") {
        formik.setFieldError("DateLog", "Invalid date");
      }
      if (typeof DOB === "string" || typeof DateLog === "string") {
        return;
      }
      createCustomerMutation.mutate({
        ...values,
        DOB: DOB.format(),
        DateLog: DateLog.format(),
      });
    },
  });

  return (
    <>
      <Modal
        show={props?.show}
        onHide={() => props?.onHide()}
        dialogClassName="new-entity-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Register</h1>
            <p>Register by filling in the following forms.</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
              {/*  */}
              <div className="row">
                <Form.Group className="col-6 mb-2">
                  <Form.Label className="mb-2">Customer Type</Form.Label>
                  <div className="d-flex gap-3 justify-content-between">
                    <Form.Check
                      inline
                      label="Retail"
                      value="Retail"
                      name="TransType"
                      type="radio"
                      checked={formik.values.TransType === "Retail"}
                      onChange={formik.handleChange}
                    />

                    <Form.Check
                      inline
                      label="Wholesaler"
                      value="Wholesaler"
                      name="TransType"
                      type="radio"
                      checked={formik.values.TransType === "Wholesaler"}
                      onChange={formik.handleChange}
                    />

                    <Form.Check
                      inline
                      label="Distributor"
                      value="Distributor"
                      name="TransType"
                      type="radio"
                      checked={formik.values.TransType === "Distributor"}
                      onChange={formik.handleChange}
                    />
                  </div>
                </Form.Group>

                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Business Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter business name"
                    name="LastName"
                    value={formik.values.LastName}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.LastName && !!formik.errors.LastName
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.LastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="row">
                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    name="FirstName"
                    value={formik.values.FirstName}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.FirstName && !!formik.errors.FirstName
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.FirstName}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    name="MiddleName"
                    value={formik.values.MiddleName}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.MiddleName && !!formik.errors.MiddleName
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.MiddleName}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="row">
                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Email Address</Form.Label>
                  <Form.Control
                    className=""
                    type="email"
                    placeholder="Enter email"
                    name="Email"
                    value={formik.values.Email}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.Email && !!formik.errors.Email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Phone Number</Form.Label>
                  <Form.Control
                    className=""
                    type="tel"
                    placeholder="(555) xxxx xxxx"
                    name="PhoneNo1"
                    value={formik.values.PhoneNo1}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.PhoneNo1 && !!formik.errors.PhoneNo1
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.PhoneNo1}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="row">
                <Form.Group className="col-md-6 mb-4">
                  <Form.Label>Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="*****"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.password && !!formik.errors.password
                      }
                    />
                    {showPassword ? (
                      <InputGroup.Text
                        onClick={() => setShowPassword(false)}
                        className="bg-white"
                      >
                        <EyeOutline />
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text
                        onClick={() => setShowPassword(true)}
                        className="bg-white"
                      >
                        <EyeOffOutline />
                      </InputGroup.Text>
                    )}
                  </InputGroup>

                  {formik.errors.password && (
                    <span className="text-danger">
                      {formik.errors.password}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="col-md-6 mb-4">
                  <Form.Label className="login-label">Gender</Form.Label>
                  <br />
                  <Form.Check
                    type="radio"
                    label="Male"
                    name="Gender"
                    value="male"
                    checked={formik.values.Gender === "male"}
                    inline
                    onChange={formik.handleChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Female"
                    value="female"
                    name="Gender"
                    checked={formik.values.Gender === "female"}
                    inline
                    onChange={formik.handleChange}
                  />
                </Form.Group>
                {/*  */}
              </div>

              <div className="row">
                <Form.Group className="col-md-6 mb-3 pb-2">
                  <Form.Label className="mb-1" htmlFor="DOB">
                    Date of Birth
                  </Form.Label>
                  <Datetime
                    dateFormat="MMM DD"
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside={true}
                    name="DOB"
                    inputProps={{
                      className: `date-input form-control ${
                        formik.touched.DOB && !!formik.errors.DOB
                          ? "is-invalid"
                          : ""
                      }`,
                      placeholder: "Select date",
                      readOnly: true,
                    }}
                    value={formik.values.DOB}
                    onChange={(date) => {
                      formik.setFieldValue("DOB", date, true);
                    }}
                    onBlur={() => formik.setFieldTouched("BOB", true)}
                  />
                  {formik.touched.DOB && !!formik.errors.DOB ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.DOB}
                    </span>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-md-6 mb-3 pb-2">
                  <Form.Label className="mb-1 text-nowrap">
                    How did Know About Us?
                  </Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    isSearchable={false}
                    value={didYouKnowOptions.find(
                      (el) => el.value === formik.values.How_Did_Know
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("How_Did_Know", value)
                    }
                    options={didYouKnowOptions}
                  />
                  <Form.Control.Feedback type="invalid" />
                </Form.Group>
              </div>

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Customer Address</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter your customer address"
                  name="ContactAddress"
                  rows={5}
                  value={formik.values.ContactAddress}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.ContactAddress &&
                    !!formik.errors.ContactAddress
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.ContactAddress}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="">
                <Form.Label className="mb-1">Company's Address</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter company's address"
                  name="CompanyAddress"
                  rows={5}
                  value={formik.values.CompanyAddress}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.CompanyAddress &&
                    !!formik.errors.CompanyAddress
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.CompanyAddress}
                </Form.Control.Feedback>
              </Form.Group>

              <CitySelector formik={formik} />
              {/*  */}
              <div className="d-flex justify-content-end mt-3">
                <button
                  className="btn btn-primary btn-md p-3 px-5"
                  type="submit"
                >
                  Register
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <ModalLoader show={createCustomerMutation.isLoading} />
    </>
  );
};
