import {
  Dropdown,
  Table,
  Form,
  Button,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  RecieptIcon,
  PageHeaderListIcon,
  BookIcon,
  ExcelIcon,
  PDFIcon,
  NoSelectedItemIcon,
  DeleteIcon,
  LinkVariantIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useIsAdmin,
  useIsCashier,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { appSettings, services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery, useMutation } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  copyText,
  customerFullName,
  defaultSelectValue,
  formatDate,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { useAuth } from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import CustomerSelector from "../utils/CustomerSelector";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { lowerCase } from "lodash";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import TableComponent from "../TableComponent";
import TransactionsTable from "../utils/TransactionsTable";

export default function AgingReport() {
  useScrollTop();
  const navigate = useNavigate();
  const initialFilterParams = {
    customerName: "",
    customerId: "",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    enableDateRange: true,
  };

  const { backendUrl } = useAuth();
  const isCashier = useIsCashier();
  const isAdmin = useIsAdmin();
  const [selectedCustomerDuePayment, setSelectedCustomerDuePayment] = useState(
    false
  );
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [showTransactionPopover, setShowTransactionPopover] = useState(false);
  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchAgingReport = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/invexcloud/aging-report?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    data = {
      count: 0,
      transactions: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.SALE_BY_INVOICE, queryParams],
    () => fetchAgingReport(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/transaction/due-invoices?${queryString.stringify(
        rest
      )}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;
    exData = exData.data.transactions.map((row) => ({
      ...row,
      Post_Time: format(new Date(row.Post_Time), "dd-MMM-yyyy hh:mm:ss a"),
    }));

    exData = exData.map((d) => [
      d.customer.LastName,
      d.Post_Time,
      d.DocType,
      d.Trans_ID,
      d.PaymentType,
      d.Debit,
      d.Credit,
      d.Username,
      d.InvoiceCat,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Customer Invoices and Payments Report"],
      [date],
      [""],
      [
        "Customer Name",
        "Trans Date",
        "Doc Type",
        "Trans ID",
        "Payment Type",
        "Debit Amount",
        "Credit Amount",
        "Username",
        "InvoiceCat",
      ],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Invoice Amount",
        currency(data?.totalInvoiceAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Payment Amount",
        currency(data?.totalPaymentAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Balance (LCY)",
        currency(data?.balance, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Invoice Amount",
        currency(data?.reversedInvoiceAmount, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Payment Amount",
        currency(data?.reversedPaymentAmount, {
          symbol: "",
        }).format(),
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setFilterParams({
      ...filterParams,
      customerId: customer.Cust_ID,
      customerName: customerFullName(customer),
    });
    setShowItemsPopover(false);
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th>...</th>
          <th>Customer</th>
          <th>Current</th>
          <th>0 - 30 Days</th>
          <th>31 - 60 Days</th>
          <th>61 - 90 Days</th>
          <th>90+ Days</th>
          <th>Total</th>
        </tr>
      </thead>
    );
  };

  const tableData = (el, index) => {
    return (
      <>
        <td>
          {/*  <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsVerticalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              {Number(el.AmountDue) <= 0 && (
                <>
                  <Dropdown.Item as="div">
                    <a
                      href={`${backendUrl}/api/invoice/pdf/invoice/${
                        el.Trans_ID
                      }`}
                      target="blank"
                    >
                      <BookIcon />
                      Open Invoice - A4
                    </a>
                  </Dropdown.Item>
                  <Dropdown.Item as="div">
                    <a
                      href={`${backendUrl}/api/invoice/pdf/invoice/${
                        el.Trans_ID
                      }?thermalPrinter=true`}
                      target="blank"
                    >
                      <BookIcon />
                      Open Invoice - Thermal Printer
                    </a>
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>{" "}
          </Dropdown> */}
        </td>
        <td>{customerFullName(el.customer)}</td>
        <td
          onClick={() =>
            openDuePayments({
              type: "current",
              typeTitle: "Current",
              customer: el.customer,
            })
          }
        >
          {currency(el.current, {
            symbol: "",
          }).format()}
        </td>
        <td
          onClick={() =>
            openDuePayments({
              type: "zeroToThirty",
              typeTitle: "0 - 30 Days",
              customer: el.customer,
            })
          }
        >
          {currency(el.zeroToThirty, {
            symbol: "",
          }).format()}
        </td>
        <td
          onClick={() =>
            openDuePayments({
              type: "thiryOneToSixty",
              typeTitle: "31 - 60 Days",
              customer: el.customer,
            })
          }
        >
          {currency(el.thiryOneToSixty, {
            symbol: "",
          }).format()}
        </td>
        <td
          onClick={() =>
            openDuePayments({
              type: "sixtyOneToNinety",
              typeTitle: "61 - 90 Days",
              customer: el.customer,
            })
          }
        >
          {currency(el.sixtyOneToNinety, {
            symbol: "",
          }).format()}
        </td>
        <td
          onClick={() =>
            openDuePayments({
              type: "ninetyPlus",
              typeTitle: "90+ Days",
              customer: el.customer,
            })
          }
        >
          {currency(el.ninetyPlus, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(el.current, {
            symbol: "",
          })
            .add(el.zeroToThirty)
            .add(el.thiryOneToSixty)
            .add(el.sixtyOneToNinety)
            .add(el.ninetyPlus)
            .format()}
        </td>
      </>
    );
  };

  const openDuePayments = (data) => {
    setSelectedCustomerDuePayment(data);
  };

  const manageTransaction = (event, el) => {
    if (event.detail === 2 && el.Trans_ID) {
      navigate(`/inventory-for-sales/manage-transaction`, {
        state: {
          TransactionID: el.Trans_ID,
        },
      });
    }
  };

  /* const handleSelectedTransaction = (transaction) => {
    setFilterParams({
      ...filterParams,
      TransactionID: transaction.TransactionID,
    });
    setShowTransactionPopover(false);
  }; */

  return (
    <section className="cashbook-wrapped">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Customer ID</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="customerId"
                          placeholder="Enter Customer ID"
                          value={filterParams?.customerId}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <CustomerSelector
                              handleSelectedCustomer={handleSelectedCustomer}
                              setShowCustomerSelector={setShowItemsPopover}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Customer Name</Form.Label>
                      <Form.Control
                        name="customerName"
                        value={filterParams?.customerName}
                        placeholder="Enter Customer Name"
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Accounts Recievables Aging Report
                  <button
                    title="Refresh"
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions">
                  {/* <button className="btn print">
              Filters <FilterIcon />
            </button> */}
                  {/* <CSVLink
                  className="btn print d-none"
                  filename={`Customer Invoices and Payments(${format(
                    new Date(),
                    "dd-MMM-yyyy hh:mm:ss a"
                  )}).csv`}
                  data={excelData}
                  ref={CSVLinkRef}
                />

                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    className="btn print"
                    disabled={isfetchingExcel}
                    bsPrefix=""
                  >
                    Export
                    <ExportIcon color="#008000" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                    className="text-center"
                  >
                    <Dropdown.Item
                      as="div"
                      onClick={onDownloadExcelData}
                      className="p-cursor"
                    >
                      Excel <ExcelIcon color="#008000" />
                    </Dropdown.Item>
                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/transaction/pdf/sale-by-invoice?${queryString.stringify(
                          rest
                        )}`}
                        target="blank"
                      >
                        PDF <PDFIcon color="#ff0000" />
                      </a>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                  {/*  <Link
                  to="/sales-and-invoicing/create-invoice"
                  className="btn bg print"
                >
                  Make Invoice <BusinessBillIcon className="pageListIcon" />
                </Link> */}
                  {/*  <button className="btn print">
                  Print <PrintIcon />
                </button>
                <button className="btn print">
                  Export <ExportIcon />
                </button>
 */}
                  {/*   <button className="btn print">
                  Open Receipt <BusinessBillIcon className="pageListIcon" />
                </button>

                <button className="btn print">
                  Open Invoice <BusinessBillIcon className="pageListIcon" />
                </button> */}

                  <button
                    onClick={() => setBtnSummaryActive(true)}
                    className="btn summaryBtn "
                  >
                    Show Summary
                  </button>
                </div>
              </header>

              <div className="px-md-4">
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={tableHead}
                  mainDataArray={data?.transactions}
                  tableDataRowFunction={tableData}
                  className="product-table text-nowrap"
                  tableRowClick={(e, el) => manageTransaction(e, el)}
                />

                {!isFetching && isSuccess && isEmpty(data?.transactions) ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>{" "}
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>

              <div
                className={
                  btnSummryActive
                    ? `summaryNew border-top open`
                    : `summaryNew border-top`
                }
              >
                <div className="summaryHeader">
                  <h2>Summary</h2>
                  {btnSummryActive && (
                    <button onClick={() => close()} className="btn p-0">
                      <SummaryCloseIcon />
                    </button>
                  )}
                </div>

                <div className="gridCont">
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.current, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Current</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftOrange">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.zeroToThirty, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total 0 - 30 Days</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftBrown">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.thiryOneToSixty, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total 31 - 60 Days</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconDown />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftDarkerGreen">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.sixtyOneToNinety, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total 61 - 90 Days</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconDown />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftDarkGreen">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.ninetyPlus, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total 90+ Days</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftBrown">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.current, {
                          symbol: "",
                        })
                          .add(data?.zeroToThirty)
                          .add(data?.thiryOneToSixty)
                          .add(data?.sixtyOneToNinety)
                          .add(data?.ninetyPlus)
                          .format()}
                      </p>
                      <p className="gridChld2">Total</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalLoader show={isfetchingExcel} />
        {selectedCustomerDuePayment && (
          <DuePaymentModal
            selectedCustomerDuePayment={selectedCustomerDuePayment}
            setSelectedCustomerDuePayment={setSelectedCustomerDuePayment}
          />
        )}
      </main>
    </section>
  );
}

function DuePaymentModal({
  selectedCustomerDuePayment,
  setSelectedCustomerDuePayment,
}) {
  const { backendUrl } = useAuth();
  const initialFilterParams = {
    Cust_ID: selectedCustomerDuePayment.customer.Cust_ID,
    type: selectedCustomerDuePayment.type,
    startDate: "",
    endDate: "",
    page: 1,
    limit: 40,
  };
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const debouncedqueryParams = useDebounce(queryParams, 800);

  const fetchDueInvoices = async (debouncedqueryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/invexcloud/customer-aging-report?&${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    data = {
      count: 0,
      transactions: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.SALE_BY_INVOICE, debouncedqueryParams],
    () => fetchDueInvoices(debouncedqueryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th>...</th>
          <th>Trans ID</th> <th>Trans Date</th>
          <th>Due Date</th>
          <th>Amount Due</th>
          <th>Payment status</th>
          <th>InvoiceCat</th>
        </tr>
      </thead>
    );
  };

  const tableData = (el, index) => {
    return (
      <>
        <td>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsVerticalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              <>
                <Dropdown.Item as="div">
                  <a
                    href={`${backendUrl}/api/invoice/pdf/invoice/${
                      el.Trans_ID
                    }`}
                    target="blank"
                  >
                    <BookIcon />
                    Open Invoice - A4
                  </a>
                </Dropdown.Item>
                <Dropdown.Item as="div">
                  <a
                    href={`${backendUrl}/api/invoice/pdf/invoice/${
                      el.Trans_ID
                    }?thermalPrinter=true`}
                    target="blank"
                  >
                    <BookIcon />
                    Open Invoice - Thermal Printer
                  </a>
                </Dropdown.Item>
              </>
            </Dropdown.Menu>{" "}
          </Dropdown>
        </td>

        <td>{el.Trans_ID}</td>
        <td>
          {el.Trans_Date ? formatDate(el.Trans_Date, "dd MMM, yyyy") : ""}
        </td>
        <td>{el.DueDate ? formatDate(el.DueDate, "dd MMM, yyyy") : ""}</td>
        <td>
          {currency(el.AmountDue, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {Number(el.AmountDue) <= 0 ? (
            <span className="text-success fw-bold">Paid</span>
          ) : (
            "..."
          )}
        </td>
        <td>{el.InvoiceCat}</td>
      </>
    );
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  return (
    <Modal
      show={true}
      size={"lg"}
      onHide={() => setSelectedCustomerDuePayment()}
      centered
    >
      <Modal.Header closeButton>Due Payments</Modal.Header>
      <Modal.Body>
        <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-4 h6">
          <h2 className="h6">
            {customerFullName(selectedCustomerDuePayment.customer)}{" "}
            {selectedCustomerDuePayment?.typeTitle}
            <button
              title="Refresh"
              onClick={() => refetch()}
              className="btn text-primary"
            >
              <CachedIcon />
            </button>
          </h2>

          {/*  <div>
            Due Date: {"  "}
            <RsDateRangePicker
              value={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
              onClean={() => clearDateRange()}
              onOk={(date) => filterByDateRange(date)}
            />
          </div> */}
        </div>

        <div className="content px-1 pb-4 pe-2 flex-grow-1">
          <TableComponent
            responsive
            borderless
            striped
            tableHeadsFunction={tableHead}
            mainDataArray={data?.transactions}
            tableDataRowFunction={tableData}
            className="product-table text-nowrap"
            tableRowClick={(e, el) => []}
          />
        </div>

        <div className="d-flex justify-content-between px-3 align-items-center pagination">
          <p className="m-0">
            Showing {data?.startIndex + 1} to{" "}
            {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
            {data.count} entries
          </p>

          <p className="fw-6">
            Total Amount Due: {currency(data?.total, { symbol: "" }).format()}
          </p>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={queryParams.page - 1}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
