import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
  InputGroup,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  copyText,
  customerFullName,
  getDataForEdit,
  maxTopPopperConfig,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  round,
  tonsToPcs,
  toTonsOrPcs,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import AddItemModal from "./AddItemModal";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { cloneDeep, isEmpty, lowerCase } from "lodash";
import EditItemModal from "./EditItemModal";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import { useUpdateEffect } from "../../utils/hooks";
import AddRodItemModal from "./AddRodItemModal";
import ConfirmDialog from "../ConfirmDialogue";
import EditIronRodItemModal from "./EditIronRodItemModal";
import PermitModal from "./PermitModal";
import { useAuth } from "../../hooks/useAuth";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import TransactionsTable from "../utils/TransactionsTable";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "./../utils/ModalLoader";
import moment from "moment";
import { format } from "date-fns";

export default function ReturnItem() {
  const paymentMethod = [
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit",
      value: "Credit",
    },
    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    },
    {
      icon: <CustomerAccountSelectIcon />,
      label: "Customer Account",
      value: "Customer Account",
    },
    {
      icon: <CashSelectIcon />,
      label: "Loyalty Account",
      value: "Loyalty Account",
    },
    {
      icon: <CreditMemoSelectIcon />,
      label: "Credit Memo",
      value: "Credit Memo",
    },
    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    {
      icon: <CashSelectIcon />,
      label: "Split Payment",
      value: "Split Payment",
    },
  ];

  const taxOptions = [
    {
      label: "None",
      value: "None",
      percentage: 0,
    },
    {
      label: "VAT",
      value: "VAT",
      percentage: 0.075,
    },
    {
      label: "WHT",
      value: "WHT",
      percentage: 0.05,
    },
    {
      label: "NCD",
      value: "NCD",
      percentage: 0.01,
    },
  ];

  const saleTypes = [
    {
      label: "Tons",
      value: "tons",
    },
    {
      label: "Pieces",
      value: "pieces",
    },
  ];

  //  const itemOverlayRef = useRef();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl } = useAuth();
  const [showTransactionPopover, setShowTransactionPopover] = useState(false);
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showItemSelectorModal, setShowItemSelectorModal] = useState(false);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [showPermitModal, setShowPermitModal] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [lockedTableData, setLockedTableData] = useState([]);
  const [editedItemIndex, setEditedItemIndex] = useState(null);

  const [selectedSale, setSelectedSale] = useState({});
  const isActiveRow = (sale, selectedSale) => {
    try {
      if (isEmpty(sale)) return false;
      if (isEmpty(selectedSale)) return false;
      return sale?.ID
        ? selectedSale?.ID === sale?.ID
        : selectedSale?.Bar_Code === sale?.Bar_Code;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  /* const convertQuantity = (Quantity, Item_Desc, type) => {
    return lowerCase(type) === "tons"
      ? pcsToTons(Quantity, Item_Desc, itemMeasurements)
      : Quantity;
  }; */

  const returnInvoiceItem = async (payload) => {
    let response = await fetch(`${backendUrl}/api/invoice/return`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const returnInvoiceItemMutation = useMutation(
    (payload) => returnInvoiceItem(payload),
    {
      onSuccess: ({ data, message }) => {
        toast.success(message);
        discard();
        refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const [TransactionID, setTransactionID] = useState("");
  const formik = useFormik({
    initialValues: {
      date: moment(),
      quantityToReturn: 0,
      Tons: 0,
      Pieces: 0,
      formatType: "",
      saleType: "Each",
      initialQuantityToReturn: 0,
    },
    validationSchema: yup.object().shape({
      quantityToReturn: yup.string().required(),
    }),
    onSubmit: async (values) => {
      if (isEmpty(selectedSale)) {
        return toast.error("Please select an item");
      }
      const Quantity =
        values?.quantityToReturn &&
        values.formatType !== "default" &&
        values.saleType === "Tons"
          ? tonsToPcs(
              values?.quantityToReturn,
              selectedSale?.Serial_Number,
              itemMeasurements
            )
          : values?.quantityToReturn;

      const RemainingQuantity =
        values?.remainingQuantity &&
        values.formatType !== "default" &&
        values.saleType === "Tons"
          ? tonsToPcs(
              values?.remainingQuantity,
              selectedSale?.Serial_Number,
              itemMeasurements
            )
          : values?.remainingQuantity;

      // console.log(Quantity, RemainingQuantity, selectedSale.QTY);

      if (Number(Quantity) > Number(selectedSale.QTY)) {
        return toast.error("You cannot return more than quantity Sold");
      }

      /* const Profit = currency(selectedSale.PriceSold, {
        symbol: "",
        separator: "",
      })
        .subtract(selectedSale.UnitCost)
        //  TODO  - subtract  - margin
        .multiply(values?.remainingQuantity)
        .format(); */

      const SubTotal = currency(values?.remainingQuantity, {
        symbol: "",
        separator: "",
      })
        .multiply(selectedSale.PriceSold)
        .format();

      // -----------------------------------------------------
      //  To  get real  profit  we  have  to  use the batch history to  know  what  they  where sold  at
      const priceSoldPerUnit =
        lowerCase(values.saleType) === "Tons"
          ? currency(selectedSale.PriceSold)
              .divide(RemainingQuantity)
              .format()
          : selectedSale.PriceSold;

      let remainder = values?.remainingQuantity;
      const updatedBatchDataToSave = [];
      let measurement = itemMeasurements.find(
        (measurement) => measurement.Size === selectedSale.Serial_Number
      );
      if (
        (lowerCase(values.saleType) !== "tons" &&
          lowerCase(values.saleType) !== "pieces") ||
        isEmpty(measurement)
      ) {
        measurement = {
          Quantity: 1,
        };
      }

      for (let [index, batch] of data.batchHistory
        .filter(
          (el) =>
            el.Bar_Code.split("-")
              .slice(0, -1)
              .join("") === selectedSale.Bar_Code
        )
        .entries()) {
        const pcsUnitCost = currency(batch.UnitCost, {
          symbol: "",
          separator: "",
        })
          .divide(measurement.Quantity)
          .format();

        if (Number(batch.Quantity) >= Number(remainder)) {
          // means we are at the last
          updatedBatchDataToSave.push({
            unitCost: pcsUnitCost,
            quantity: remainder,
            totalUnitCost: currency(pcsUnitCost, {
              symbol: "",
              separator: "",
            })
              .multiply(remainder)
              .format(),
            totalUnitPrice: currency(priceSoldPerUnit, {
              symbol: "",
              separator: "",
            })
              .multiply(remainder)
              .format(),
            totalMargin: currency(selectedSale?.Margin || 0)
              .divide(measurement.Quantity)
              .multiply(remainder).value,
            totalIncentive: currency(selectedSale?.Incentive || 0)
              .divide(measurement.Quantity)
              .multiply(remainder).value,
          });

          if (lowerCase(values.saleType) === "pieces") {
            values.UnitCost = pcsUnitCost;
          }
          break;
        } else {
          updatedBatchDataToSave.push({
            unitCost: pcsUnitCost,
            quantity: batch.Quantity,
            totalUnitCost: currency(pcsUnitCost, {
              symbol: "",
              separator: "",
            })
              .multiply(batch.Quantity)
              .format(),
            totalUnitPrice: currency(priceSoldPerUnit, {
              symbol: "",
              separator: "",
            })
              .multiply(batch.Quantity)
              .format(),
            totalMargin: currency(selectedSale?.Margin || 0)
              .divide(measurement.Quantity)
              .multiply(batch.Quantity).value,
            totalIncentive: currency(selectedSale?.Incentive || 0)
              .divide(measurement.Quantity)
              .multiply(batch.Quantity).value,
          });

          remainder = Number(
            currency(remainder, {
              symbol: "",
              separator: "",
            })
              .subtract(batch.Quantity)
              .format()
          );
        }
      }

      const Profit = updatedBatchDataToSave
        .map((el) =>
          currency(el.totalUnitPrice)
            .subtract(el.totalUnitCost)
            .subtract(el.totalIncentive)
            .format()
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        );

      const Total_Incentive = updatedBatchDataToSave
        .map((el) => el.totalIncentive)
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        );

      /*  console.log(Profit.value, Total_Incentive.value, updatedBatchDataToSave);
      return; */
      //`------------------------------------------------------

      returnInvoiceItemMutation.mutate({
        TransactionID,
        sale: selectedSale,
        quantityToReturn: values?.quantityToReturn,
        remainingQuantity: values.remainingQuantity,
        Profit,
        SubTotal,
        SubTotalOfReturnItem: currency(values?.quantityToReturn, {
          symbol: "",
          separator: "",
        })
          .multiply(selectedSale.PriceSold)
          .format(),
        returnCost: currency(values?.quantityToReturn, {
          symbol: "",
          separator: "",
        })
          .multiply(selectedSale.UnitCost)
          .format(),
        Quantity,
        RemainingQuantity,
        customer: data.customer,
        saleType: values.saleType,
        Total_Incentive,
      });
    },
    onReset: () => {},
  });

  const discard = () => {
    formik.resetForm();
    setTableData([]);
    setSelectedCustomer(null);
  };

  const handleRowClick = (e, index) => {
    if (e.target.tagName === "TD") setEditedItemIndex(index);
  };

  const handleSelectedTransaction = (transaction) => {
    setTransactionID(transaction.TransactionID);
    setShowTransactionPopover(false);

    // fetch sales data
  };

  const getSales = async ({ TransactionID }) => {
    let response = await fetch(
      `${backendUrl}/api/transaction/sales/${TransactionID}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (!isEmpty(selectedSale) && !isEmpty(data?.sales) && TransactionID) {
      handleSelectedSale(
        data.sales.find((sale) =>
          sale?.ID
            ? selectedSale.ID === sale?.ID
            : selectedSale.Bar_Code === sale.Bar_Code &&
              sale.TransactionID === TransactionID
        )
      );
    }
    return data;
  };

  const transaction = useMemo(() => cloneDeep({ TransactionID }), [
    TransactionID,
  ]);

  const { data = { sales: [] }, refetch } = useQuery(
    [queryActions.GET_SALES_BY_TRANSACTION_ID, transaction],
    () => getSales(transaction),
    {
      keepPreviousData: false,
      enabled: Boolean(transaction?.TransactionID),
    }
  );

  const handleSelectedSale = (sale) => {
    let saleType, quantityResolved;
    setSelectedSale(sale);

    //  old format  - user  must  sell  either  ton or  pcs not both
    // get format type
    const { Tons, Pieces, formatType } = getDataForEdit({
      qty: sale?.QTY || 0,
      desc: sale?.Serial_Number,
      itemMeasurements,
    });

    // sale Type
    saleType =
      Number(Tons) > 0
        ? "Tons"
        : Number(Pieces) > 0 && formatType !== "default"
        ? "Pieces"
        : "Each";

    quantityResolved = Number(Tons) > 0 ? Tons : Pieces;
    //---

    //  for new records - 03-02-2022
    if (sale?.saleType) {
      saleType = sale?.saleType;
      quantityResolved =
        lowerCase(sale.saleType) === "tons"
          ? round(
              pcsToTons(sale.QTY || 0, sale?.Serial_Number, itemMeasurements),
              0.1
            )
          : sale.QTY;
    }
    //---

    formik.setValues({
      ...formik.values,
      Tons,
      Pieces,
      formatType,
      saleType,
      quantityToReturn: quantityResolved,
      initialQuantityToReturn: quantityResolved,
    });

    handleQuantityToReturn(quantityResolved);
  };

  const handleQuantityToReturn = (value, name) => {
    formik.setFieldValue("quantityToReturn", value);
    formik.setFieldValue(
      "remainingQuantity",
      currency(formik.values.initialQuantityToReturn, {
        separator: "",
        symbol: "",
      })
        .subtract(value)
        .format()
    );
  };

  return (
    <main className="create-invoice">
      <PageHeader
        name="Return Invoice Item"
        description="Invoice Adjustment"
        icon={<CreateInvoiceIcon />}
      />
      <div className="p-3 content">
        <div className="d-md-flex content-holder rounded">
          <section
            /*  style={
              formik.values.pendingTransaction
                ? {
                    pointerEvents: "none",
                  }
                : {}
            } */
            className="item-details"
          >
            <div>
              <header className="h-auto">
                <Form.Group className="mt-4 mb-3 col-md-6">
                  <InputGroup>
                    <Form.Control
                      className=""
                      type="text"
                      placeholder="Transaction ID"
                      name="TransactionID"
                      value={TransactionID}
                      onChange={(e) => setTransactionID(e.target.value)}
                    />

                    <Popover
                      isOpen={showTransactionPopover}
                      onClickOutside={() => setShowTransactionPopover(false)}
                      content={() => (
                        <TransactionsTable
                          handleSelectedTransaction={handleSelectedTransaction}
                        />
                      )}
                      position="bottom"
                    >
                      <InputGroup.Text
                        onClick={() =>
                          setShowTransactionPopover(!showTransactionPopover)
                        }
                      >
                        <MagnifyIcon />
                      </InputGroup.Text>
                    </Popover>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Bar_Code}
                  </Form.Control.Feedback>
                </Form.Group>
              </header>

              <div className="selected-data-area">
                <div className="table-holder">
                  <Table
                    responsive
                    borderless
                    hover
                    striped
                    className="product-table  text-nowrap"
                  >
                    <thead>
                      <tr>
                        <th>Size/Desc</th>
                        <th>Item Name</th>
                        <th>Price Sold</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                        <th>Discount</th>
                        <th>Barcode</th>
                        <th>Product name</th>
                        <th>Unit Price</th>
                        <th>Profit</th>
                        <th>Warranty</th>
                        <th>Warranty Duration</th>
                        <th>Overwrite officer</th>
                        {/*  <th>Cost</th> */}
                        {/*  <th>Type</th> */}
                        <th>Unit Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.sales.map((el, index) => (
                        <tr
                          key={index}
                          onClick={(e) => handleSelectedSale(el)}
                          className={`p-cursor ${
                            isActiveRow(el, selectedSale)
                              ? "active-row-blue"
                              : ""
                          }`}
                        >
                          <td>{el?.Serial_Number || "..."}</td>
                          <td>{el.Item_Name}</td>
                          <td>
                            {currency(el.PriceSold, { symbol: "" }).format()}
                          </td>
                          <td>
                            {qtyFormatToString(
                              qtyFormat(
                                el?.QTY,
                                el?.Serial_Number,
                                itemMeasurements
                              )
                            )}
                          </td>
                          <td>
                            {currency(el.SubTotal, { symbol: "" }).format()}
                          </td>
                          <td>
                            {currency(el.Discount, { symbol: "" }).format()}
                          </td>
                          <td>{el.Bar_Code}</td>
                          <td>{el.Product_Name || el.ProductName}</td>
                          <td>
                            {currency(el.Unit_Price, { symbol: "" }).format()}
                          </td>
                          <td>
                            {currency(el.Profit, { symbol: "" }).format()}
                          </td>
                          <td>{el.Warranty}</td>
                          <td>{el.Warrant_Duration}</td>
                          <td>{"..."}</td>
                          {/*  <td>
                            {currency(Number(el.UnitCost), { symbol: "" })
                              .multiply(
                                convertQuantity(
                                  el.Quantity,
                                  el.Serial_Number,
                                  el.saleType
                                )
                              )
                              .format()}
                          </td> */}
                          {/*  <td>{el.Item_Type || "..."}</td> */}
                          <td>
                            {currency(el.UnitCost, { symbol: "" }).format()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                {/*  */}

                {/*  No item  */}
                {isEmpty(data?.sales) ? (
                  <div className="no-item my-4">
                    <div className="info">
                      <NoSelectedItemIcon />
                      <h2 className="mb-2">No Items Found</h2>
                      <p>...</p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            {/*    <div className="d-flex justify-content-end total-info d-none">
              <table className="table table-borderless balance">
                <tbody>
                  <tr>
                    <td>Subtotal</td>
                    <td>
                      {currency(subTotal, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Discount</td>
                    <td>
                     

                      {currency(discount, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Amount Due</td>
                    <td>
                      {currency(amountDue, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Balance</td>
                    <td>
                      {currency(balance, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Total</td>
                    <td>
                      {currency(grandTotal, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          </section>
          <section className="customer">
            <h2>Customer</h2>

            <div className="d-flex justify-content-between">
              <div className="avatar">
                <UserSolidIcon />
              </div>
              {data?.customer ? (
                <div className="customer-actions d-flex justify-content-between flex-grow-1">
                  <div>
                    <h3>{data?.customer?.LastName}</h3>
                    <p>{data?.customer?.Cust_ID}</p>
                  </div>
                </div>
              ) : (
                <div className="customer-actions flex-grow-1">
                  <h3>No customer selected</h3>
                  <p>...</p>
                </div>
              )}
            </div>

            <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
              <section>
                <Table borderless className="mb-2">
                  <tbody>
                    <tr>
                      <td className="fw-bold">Item Amount:</td>
                      <td>
                        {currency(selectedSale?.SubTotal, {
                          symbol: "",
                        }).format()}
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-bold">Item Selected:</td>
                      <td>{selectedSale?.Item_Name}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold">Invoice Cat:</td>
                      <td>{selectedSale?.ProductName}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold">Prepared By:</td>
                      <td>{selectedSale?.UserName}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold">Date:</td>
                      <td>
                        {selectedSale && selectedSale?.Date_Log
                          ? format(
                              new Date(selectedSale?.Date_Log),
                              "dd MMM yyyy"
                            )
                          : "..."}
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <div className="px-2">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Quantity to Return ({formik.values.saleType}){" "}
                    </Form.Label>
                    <NumberCustomInput
                      type="text"
                      placeholder=""
                      name="quantityToReturn"
                      value={formik.values.quantityToReturn}
                      onValueChange={(value, name) =>
                        handleQuantityToReturn(value, name)
                      }
                      isInvalid={
                        formik.touched.quantityToReturn &&
                        !!formik.errors.quantityToReturn
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.quantityToReturn}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </section>

              <section className="buttons">
                <Button
                  type="button"
                  variant="outline-primary"
                  // className="border-0"
                  onClick={() => discard()}
                >
                  Refresh
                </Button>
                <Button type="submit" variant="primary">
                  Return Selected Item
                </Button>
              </section>
            </Form>
          </section>
        </div>
      </div>

      <ModalLoader show={returnInvoiceItemMutation.isLoading} />
    </main>
  );
}
