import { Link, useLocation } from "react-router-dom";

const sideBars = [
  { name: "Orders", link: "/store/account/orders" },
  { name: "Invoices", link: "/store/account/invoices" },
  { name: "Payments", link: "/store/account/payments" },
  { name: "Ledger", link: "/store/account/ledger" },
  { name: "Profile", link: "/store/account/profile" },
];

export const CustomerSideBar = () => {
  const location = useLocation();

  return (
    <>
      <div className="border-bottom">
        <div className="text-center py-3">
          <h4>My Account</h4>
        </div>
      </div>

      {sideBars.map((el, i) => (
        <div
          key={i}
          className={`p-3 py-4 cursor ${
            location.pathname.startsWith(`${el.link}`) ? "active-bg" : ""
          }`}
        >
          <Link to={el.link}>
            <h6>
              <p>{el.name}</p>
            </h6>
          </Link>
        </div>
      ))}
    </>
  );
};
