import { Navigate, Route } from "react-router";
import { lazy } from "react";

import Items from "./Items";
import StoreLayout from "./StoreLayout";
import { CustomerAccount } from "../CustomerDetails/CustomerAccount";
import StoreOrder from "../SalesAndInvoicing/StoreOrder";
import ContactUs from "./ContactUs";

// Lazy imports ------------------------------------------------------
const Profile = lazy(() => import("../CustomerDetails/Profile"));
const CustomerLedger = lazy(() => import("../CustomerDetails/Ledger"));
const CustomerPayments = lazy(() => import("../CustomerDetails/Payments"));
const CustomerInvoices = lazy(() => import("../CustomerDetails/Invoices"));
const CustomerOrders = lazy(() => import("../CustomerDetails/Orders"));
const Cart = lazy(() => import("../SalesAndInvoicing/Cart"));

const StoreRoutes = (
  <Route element={<StoreLayout />}>
    <Route path="store">
      <Route index element={<Navigate to="home" />} />
      <Route path="" element={<Items />} />
      <Route path="home" element={<Items />} />
      <Route path="cart" element={<Cart />} />
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="account" element={<CustomerAccount />}>
        <Route index element={<Navigate to="profile" />} />
        <Route path="profile" element={<Profile />} />
        <Route path="ledger" element={<CustomerLedger />} />
        <Route path="payments" element={<CustomerPayments />} />
        <Route path="invoices" element={<CustomerInvoices />} />
        <Route path="orders" element={<CustomerOrders />} />
      </Route>

      <Route path="categories" element={<Items />}>
        <Route path=":categoryName" element={<Items />} />
      </Route>

      <Route path="products" element={<Items />}>
        <Route path=":productName" element={<Items />} />
      </Route>

      <Route path="products/:" element={<Items />} />
      <Route path="track-order" element={<StoreOrder />} />
      <Route
        path="customer-products"
        element={<Items usage="customer-products" />}
      />
    </Route>
  </Route>
);

export default StoreRoutes;
