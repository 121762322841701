import moment from "moment";
import * as yup from "yup";
export const initialValues = {
  password: "",
  Gender: "Male",
  Cust_ID: String(Date.now()),
  FirstName: "",
  LastName: "",
  MiddleName: "",
  Email: "",
  PhoneNo1: "",
  DOB: moment(),
  DateLog: moment(),
  CompanyAddress: "",
  ContactAddress: "",
  credit: 0,
  TransType: "Retail",
  How_Did_Know: "Walk In",
  state: "",
  LGA: "",
};

export const schema = yup.object().shape({
  FirstName: yup.string().required("First name is required"),
  LastName: yup.string().required("Business name is required"),
  Email: yup.string().email(),
  PhoneNo1: yup.string().required("Phone number is required"),
  DOB: yup.string().required("Date of birth is required"), // moment
  DateLog: yup.string().required(), // moment
  credit: yup.string(),
  password: yup.string("Password is required"),
});

export const schemaLogin = yup.object().shape({
  Email: yup.string().email(),
  password: yup.string("Password is required"),
});
