import { Button, Form } from "react-bootstrap";
import { DropdownCloseIcon } from "../components/Icons";

// GA - Added View Only

export function FilesAttachments({
  files = [],
  setFiles,
  url,
  deleteImage,
  viewOnly = false,
}) {
  function isImage(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }

  return (
    <div className="container">
      <div className="row">
        {files?.map((el, index) => (
          <div key={index} className="d-flex align-items-center col-6 mb-3">
            {el?.fileId && url ? (
              <a
                className="text-primary"
                href={`${url}/${el.systemFileName}`}
                target="_blank"
              >
                {isImage(el.systemFileName) ? (
                  <img
                    src={`${url}/${el.systemFileName}`}
                    className="img-fluid"
                    alt={el.systemFileName}
                    /*   alt={el?.fileId} */
                    width={100}
                  />
                ) : (
                  <span>{el.name}</span>
                )}
              </a>
            ) : (
              <img
                src={URL.createObjectURL(el)}
                /* alt={`{new${index}}`} */
                alt={el?.name}
                className="img-fluid"
                width={100}
              />
            )}

            {!viewOnly && (
              <Button
                variant=""
                size="sm"
                onClick={() => {
                  if (deleteImage) {
                    deleteImage(index);
                  } else {
                    setFiles(files.filter((el, i) => i !== index));
                  }
                }}
              >
                <DropdownCloseIcon width={16} />
              </Button>
            )}
            <hr className="m-0" />
          </div>
        ))}
      </div>

      {!viewOnly && (
        <Form.Label className="btn btn-light-blue">
          <span>Add Files </span>
          <Form.Control
            type="file"
            className="d-none"
            multiple
            onChange={(e) => setFiles([...files, ...e.target.files])}
          />
        </Form.Label>
      )}
    </div>
  );
}
