import { Form, InputGroup, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";
import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import ModalLoader from "../utils/ModalLoader";

import { useAuth } from "../../hooks/useAuth";
import { initialValues, schemaLogin } from "./types";
import "../../assets/scss/new-entity-modal.scss";

export const LoginCustomer = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  const { customerBackendUrl: backendUrl, loginCustomer } = useAuth();

  const loginUser = async (values) => {
    let response = await fetch(`${backendUrl}/api/customers/login`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      body: JSON.stringify({
        ...values,
      }),
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      if (response.errors) formik.setErrors(response.errors);
      throw new Error(response.message);
    }

    return await response.json();
  };

  const createCustomerMutation = useMutation((payload) => loginUser(payload), {
    onSuccess: (data) => {
      loginCustomer(data?.user, location);
      toast.success("Login Successfully");
      if (props.loggedInCustomer) props.loggedInCustomer(data?.user);
      props?.onHide();
    },
    onError: (errors) => {
      console.log(errors);
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schemaLogin,
    onSubmit: (values) => {
      createCustomerMutation.mutate({
        ...values,
      });
    },
  });

  return (
    <>
      <Modal
        show={props?.show}
        onHide={() => props?.onHide()}
        dialogClassName="small-modal"
        backdropClassName={`global-backdrop`}
        size="md"
        centered={true}
        animation={false}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Login</h1>
            <p>Login by filling in the following forms.</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-1">
            <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
              {/*  */}
              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Email Address</Form.Label>
                <Form.Control
                  className=""
                  type="email"
                  placeholder="Enter email"
                  name="Email"
                  value={formik.values.Email}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Email && !!formik.errors.Email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Email}
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}
              <Form.Group className="mb-4">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="*****"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.password && !!formik.errors.password
                    }
                  />
                  {showPassword ? (
                    <InputGroup.Text
                      onClick={() => setShowPassword(false)}
                      className="bg-white"
                    >
                      <EyeOutline />
                    </InputGroup.Text>
                  ) : (
                    <InputGroup.Text
                      onClick={() => setShowPassword(true)}
                      className="bg-white"
                    >
                      <EyeOffOutline />
                    </InputGroup.Text>
                  )}
                </InputGroup>

                {formik.errors.password && (
                  <span className="text-danger">{formik.errors.password}</span>
                )}
              </Form.Group>

              {/*  */}
              <div className="d-flex justify-content-end">
                <button className="btn btn-primary w-100 p-3" type="submit">
                  Login
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <ModalLoader show={createCustomerMutation.isLoading} />
    </>
  );
};
