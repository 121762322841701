import { Badge, Button, Dropdown, Form } from "react-bootstrap";
import "../../assets/scss/store/StoreNavBar.scss";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { backendApis } from "../../config";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import Avatar from "../utils/Avatar";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Fragment, useRef } from "react";
import Select from "react-select";
import { RegisterCustomer } from "../CustomerDetails/Register";
import { LoginCustomer } from "../CustomerDetails/Login";
import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil, initialGeneralSettings } from "../../utils/helpers";
import { CartIcon, LocationIcon, PhoneIcon } from "./StoreIcons";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useStoreActions, useStoreState } from "easy-peasy";
import { LinkWithQuery } from "../utils/LinkWithQuery";
import currency from "currency.js";
import useDebounce from "../../utils/hooks";
import eventBus from "../../utils/EventBus";
import { truncate } from "lodash";

export default function StoreNavBar() {
  const totalInCart = useStoreState((state) =>
    state.cart
      .map((el) => el.Quantity)
      .reduce((a, b) => currency(a).add(b).value, 0)
  );
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const location = useLocation();
  const navHolder = useRef();
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );
  const { q = "" } = useSearchParams();
  const [storeSearchString, setStoreSearchString] = useState(q);
  const debouncedStoreSearchString = useDebounce(storeSearchString, 500);

  useEffect(() => {
    eventBus.dispatch("STORE_SEARCH_STRING", debouncedStoreSearchString);
  }, [debouncedStoreSearchString]);

  const {
    logoutCustomer,
    customerBackendUrl: backendUrl,
    customer,
  } = useAuth();

  const navigate = useNavigate();
  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/store/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.products = data.products.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
      to: el.Product_Name,
      name: el.Product_Name,
    }));
    data.categories = data.categories.map((el) => ({
      value: el.Cat_Name,
      label: el.Cat_Name,
      to: el.Cat_Name,
      name: el.Cat_Name,
    }));

    setItemMeasurements(data.itemMeasurements);
    setGeneralSettings({ ...initialGeneralSettings, ...data.settings });
    return data;
  };

  const {
    data = { categories: [], products: [], company: {}, settings: {} },
  } = useQuery(["STORE_NAV_SET_UP"], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const navItems = useMemo(
    () => [
      {
        name: "Home",
        to: "store/home",
      },
      { name: "Categories", to: "categories", childRoutes: data?.categories },
      { name: "Manufacturer", to: "products", childRoutes: data?.products },
      ...(customer
        ? [
            {
              name: "Customer Products",
              to: "store/customer-products",
            },
          ]
        : []),
      { name: "Contact Us", to: "store/contact-us" },
    ],
    [data?.products, data?.categories, customer]
  );

  const logOut = async () => {
    logoutCustomer();
    await fetchActionsUtil(`${backendUrl}/api/customers/logout`, "GET");
    navigate("/store", { replace: true });
    window.location.reload();
  };

  return (
    <nav className="storeNavBar">
      <div className="top">
        <div className="d-flex">
          <NavLink to={"/store"} className="logo-area">
            <img
              src={`${backendUrl}/images/company-logo.png`}
              width="50"
              /*    height="26"   */
              /*  style={{ width: "8rem" }} */
            />
            <h1>{data?.settings?.storeName || "Store"}</h1>
          </NavLink>

          <div className="action">
            <Button variant="white">
              <PhoneIcon />{" "}
              {truncate(data?.company?.Phone || "0000000000", {
                length: 15,
              })}
            </Button>
            {/* <Dropdown>
              <Dropdown.Toggle variant="white" bsPrefix={``}>
                <LocationIcon />
                Location
              </Dropdown.Toggle>
              <Dropdown.Menu
                popperConfig={{
                  strategy: "fixed",
                }}
                renderOnMount
              >
                {backendApis.map((el) => (
                  <Dropdown.Item
                    key={el.name}
                    as="button"
                       className={`${authUser?.company === el.name ? "active" : ""}`} 
                    //  onClick={() => switchCompany(el)}
                  >
                    {el.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
          </div>

          <div className="mx-3 d-flex align-items-center search-parent">
            <div className="global-search-area">
              <MagnifyIcon />
              <Form.Control
                name="q"
                value={storeSearchString}
                onChange={(e) => setStoreSearchString(e.target.value)}
                placeholder="Search Item..."
              />
            </div>{" "}
            {/* <Select
              placeholder="All Category"
              classNamePrefix={"form-select"}
            /> */}
          </div>
        </div>

        <div className="d-flex profile-area">
          <Link variant="" className="btn" to={"/store/cart"}>
            <CartIcon />
            <Badge bg="danger">
              {currency(totalInCart, {
                symbol: "",
                precision: 0,
              }).format()}
            </Badge>
          </Link>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2"
            height="42"
            viewBox="0 0 2 42"
            fill="none"
          >
            <path d="M1 0V42" stroke="#E2E8F0" />
          </svg>

          <Dropdown>
            <Dropdown.Toggle
              variant="white"
              className="btn d-flex border-0 align-items-center p-1"
            >
              <Avatar
                className="avatar"
                name={customer?.FirstName}
                userId={customer?.Cust_ID}
                Cust_ID={customer?.Cust_ID}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
            >
              {customer && (
                <Dropdown.Item as="div">
                  <Link to={"/store/account/profile"}>Profile</Link>
                </Dropdown.Item>
              )}
              {!customer && (
                <Dropdown.Item
                  as="div"
                  className="p-cursor"
                  onClick={() => setShowLogin(true)}
                >
                  Login
                </Dropdown.Item>
              )}
              {!customer && (
                <Dropdown.Item
                  as="div"
                  className="p-cursor"
                  onClick={() => setShowRegister(true)}
                >
                  Register
                </Dropdown.Item>
              )}
              <Dropdown.Item as="div">
                <Link to={"/store/track-order"}>Track Order</Link>
              </Dropdown.Item>
              {customer && (
                <Dropdown.Item as="div" className="p-cursor" onClick={logOut}>
                  Log Out
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="bottom">
        {" "}
        <ul /* ref={navHolder} */ /* className={navOpen ? "open" : "closed"} */>
          {navItems.map((item, index) => (
            <li key={index}>
              {!item.childRoutes ? (
                <Link to={item?.to ? item.to : ""}>{item.name}</Link>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle variant="white">{item.name}</Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                  >
                    {item.childRoutes.map((el, index) => (
                      <Fragment key={index}>
                        {el.to ? (
                          <Dropdown.Item as="div">
                            <LinkWithQuery
                              to={`/store${item.to ? `/${item.to}` : ""}/${
                                el.to
                              }`}
                            >
                              {el.name}
                            </LinkWithQuery>
                          </Dropdown.Item>
                        ) : el.onClick ? (
                          <Dropdown.Item
                            onClick={() => el.onClick()}
                            as="button"
                          >
                            {el.name}{" "}
                            <span className="shortcut">{el?.shortcut}</span>
                          </Dropdown.Item>
                        ) : el.grandchildRoutes ? (
                          <div className="position-relative submenu">
                            <button
                              className="dropdown-item justify-content-between submenu-toggle px-3"
                              href="#"
                            >
                              {el.name} <span>&raquo;</span>
                            </button>
                            {el.grandchildRoutes && (
                              <ul className="dropdown-menu dropdown-submenu text-left">
                                {el.grandchildRoutes.map((grandEl) => (
                                  <li className="w-100" key={grandEl.name}>
                                    <Dropdown.Item as="div">
                                      <Link to={`/${grandEl.to}`}>
                                        {grandEl.name}
                                      </Link>
                                    </Dropdown.Item>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        ) : null}
                      </Fragment>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </li>
          ))}
        </ul>{" "}
      </div>
      {showLogin && (
        <LoginCustomer show={showLogin} onHide={() => setShowLogin(false)} />
      )}
      {showRegister && (
        <RegisterCustomer
          show={showRegister}
          onHide={() => setShowRegister(false)}
        />
      )}
    </nav>
  );
}
